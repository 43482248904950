import { useEffect } from "react";
import Aos from "aos";
import Marquee from "react-fast-marquee";
import ImgSection1 from "./assets/img/img-section-intro.png";
import ImgBgTop from "./assets/img/img-bg-top.png";
import ImgLink from "./assets/img/img-link.png";
import ImgAvailable1 from "./assets/img/img-available-1.png";
import ImgAvailable2 from "./assets/img/img-available-2.png";
import ImgAvailable3 from "./assets/img/img-available-3.png";
import ImgAvailable4 from "./assets/img/img-available-4.png";
import ImgSection2 from "./assets/img/img-about-us.svg";
import Icon1 from "./assets/img/icon-1.png.png";
import Icon2 from "./assets/img/icon-2.png.png";
import Icon3 from "./assets/img/icon-3.png.png";
import ImgSlider1 from "./assets/img/img-slider-1.png";
import ImgSlider2 from "./assets/img/img-slider-2.png";
import ImgSlider3 from "./assets/img/img-slider-3.png";
import ImgSlider4 from "./assets/img/img-slider-4.png";
import ImgSlider5 from "./assets/img/img-slider-5.png";
import ImgSlider6 from "./assets/img/img-slider-6.png";
import ImgDexData from "./assets/img/img-dex-data.png";
import ImgBtnSocial1 from "./assets/img/img-btn-social-1.png";
import ImgBtnSocial2 from "./assets/img/img-btn-social-2.png";
import ImgCommunity from "./assets/img/img-community.gif";
import ImgSectionToward from "./assets/img/img-section-toward.png";
import ImgStage from "./assets/img/img-stage.png";
import IconF1 from "./assets/img/icon-mail.png";
import IconF2 from "./assets/img/icon-twitter.png";
import IconF3 from "./assets/img/icon-telegram.png";
import "./App.scss";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    Aos.init({
      duration: 1500,
    });
  }, []);
  return (
    <div className="App">
      <div className="container">
        <header className="page-header">
          <div className="img-bg-top">
            <img src={ImgBgTop} alt="" />
          </div>
          <div className="container wrapper">
            <div className="logo-app">
              <img src={ImgSection1} alt="" />
            </div>
            <nav className="nav-menu">
              <ul>
                <li className="strikethrough">
                  <a href="/">home</a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/NyanCatSolana"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    twitter
                  </a>
                </li>
                <li>
                  <a
                    href="https://t.me/NyanCatSolana"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    telegram
                  </a>
                </li>
                <li>
                  <a href="">contact us</a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <main className="page-main">
          <section className="section-intro">
            <div data-aos="fade-up" className="container wrapper">
              <div className="content">
                <p className="subtitle">welcome to</p>
                <h1 className="title">$NYAN CAT</h1>
                <p className="des">
                  a unique memecoin that commemorates the milestones of the
                  crypto world
                </p>
                <div className="list-btn">
                  <a href="">
                    <img src={ImgLink} alt="" />
                    <span>BUY NOW</span>
                  </a>
                  <a href="">
                    <img src={ImgLink} alt="" />
                    <span>VIEW CHART</span>
                  </a>
                </div>
                <div className="list-available">
                  <p className="available-title">Available On</p>
                  <div className="row-center">
                    <div className="item-available">
                      <img src={ImgAvailable1} alt="" />
                    </div>
                    <div className="item-available">
                      <img src={ImgAvailable2} alt="" />
                    </div>
                    <div className="item-available">
                      <img src={ImgAvailable3} alt="" />
                    </div>
                    <div className="item-available">
                      <img src={ImgAvailable4} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="img-right">
                <img src={ImgSection1} alt="" />
              </div>
            </div>
          </section>
          <section className="section-about-us">
            <div data-aos="fade-up" className="container wrapper">
              <div className="content-top">
                <p>About us</p>
                <h3>
                  Discover the story
                  <br />
                  behind us
                </h3>
              </div>
              <div className="content-bottom">
                <div className="img-left">
                  <img src={ImgSection2} alt="" />
                </div>
                <div className="right">
                  <h4>$NYAN CAT</h4>
                  <p className="des">
                    On April 2, 2011, the GIF animation of the cat was posted by
                    25-year-old Christopher Torres of Dallas, Texas, who uses
                    the name "prguitarman", on his website LOL-Comics.[2] Torres
                    explained in an interview where the idea for the animation
                    came from: "I was doing a donation drive for the Red Cross
                    and in-between drawings in my Livestream video chat, two
                    different people mentioned I should draw a 'Pop Tart' and a
                    'cat'." In response, he created a hybrid image of a Pop-Tart
                    and a cat, which was developed a few days later into the
                    animated GIF.[3] Nyan Cat was based on a real cat: Torres'
                    pet cat Marty, who died in November 2012 from feline
                    infectious peritonitis.[
                  </p>
                  <div className="list-info">
                    <div className="item">
                      <div className="icon">
                        <img src={Icon1} alt="" />
                      </div>
                      <p className="text">LP BURNT</p>
                    </div>
                    <div className="item">
                      <div className="icon">
                        <img src={Icon2} alt="" />
                      </div>
                      <p className="text">Total Supply : 100,000,000</p>
                    </div>
                    <div className="item">
                      <div className="icon">
                        <img src={Icon3} alt="" />
                      </div>
                      <p className="text">Final TAX : 0/0</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section data-aos="fade-up" className="section-slider">
            <Marquee speed={40} gradient={false} className="list-img">
              <div className="item-slide">
                <img src={ImgSlider1} alt="Image 1" />
              </div>
              <div className="item-slide">
                <img src={ImgSlider2} alt="Image 2" />
              </div>
              <div className="item-slide">
                <img src={ImgSlider3} alt="Image 3" />
              </div>
              <div className="item-slide">
                <img src={ImgSlider4} alt="Image 4" />
              </div>
              <div className="item-slide">
                <img src={ImgSlider5} alt="Image 5" />
              </div>
              <div className="item-slide">
                <img src={ImgSlider6} alt="Image 6" />
              </div>
            </Marquee>
          </section>
          {/* <section className="section-dexscreener">
            <div data-aos="fade-up" className="container wrapper">
              <div className="content-top">
                <p>DexScreener</p>
                <h3>Live Chart</h3>
              </div>
              <div className="img-bottom">
                <img src={ImgDexData} alt="" />
              </div>
            </div>
          </section> */}
          <section className="section-community">
            <div data-aos="fade-up" className="container wrapper">
              <div className="img-top">
                <img src={ImgCommunity} alt="" />
              </div>
              <div className="content-bottom">
                <h3>
                  JOIN THE
                  <span> COMMUNITY</span>
                </h3>
                <p>
                  Join our vibrant community and be part of something
                  extraordinary!
                </p>
                <div className="socials">
                  <a
                    className="item"
                    href="https://twitter.com/NyanCatSolana"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={ImgBtnSocial1} alt="" />
                    <span>TWITTER</span>
                  </a>
                  <a
                    className="item"
                    href="https://t.me/NyanCatSolana"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={ImgBtnSocial2} alt="" />
                    <span>TELEGRAM</span>
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section className="section-toward">
            <div data-aos="fade-up" className="container wrapper">
              <div className="content">
                <div className="left">
                  <h3>Towards Extraordinary Success</h3>
                  <p>
                    Follow this roadmap to achieve extraordinary success,
                    through a series of stages that are directed and full of
                    potential.
                  </p>
                  <div className="img-left">
                    <img src={ImgSectionToward} alt="" />
                  </div>
                </div>
                <div className="right">
                  <div className="list-stage">
                    <div className="item-stage">
                      <div className="row-center">
                        <div className="content">
                          <h3>Stage 1</h3>
                          <ul>
                            <li>Contract Deployment</li>
                            <li>Community growth</li>
                            <li>SolScan update</li>
                            <li>DEX Screener update</li>
                          </ul>
                        </div>
                        <div className="img-stage">
                          <img src={ImgStage} alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="item-stage">
                      <h3>Stage 2</h3>
                      <ul>
                        <li>CEX Listing</li>
                        <li>Influencer Partnerships</li>
                        <li>CMC & CG Listing</li>
                        <li>Youtube Videos</li>
                      </ul>
                    </div>
                    <div className="item-stage">
                      <h3>Stage 3</h3>
                      <ul>
                        <li>Twitter Spaces</li>
                        <li>Chinese Community Expansion</li>
                        <li>Billboard Adsa</li>
                        <li>Utility Release</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <footer className="page-footer">
          <div className="container wrapper">
            <div className="img-footer">
              <img src={ImgSection1} alt="" />
            </div>
            <div className="content">
              <p>
                Stay active and engaged with us, as we embark on an incredible
                journey together!
              </p>
              <a href="" className="active">
                Active
                <span> With Us{">>"}</span>
              </a>
            </div>
            <div className="list-socials">
              <a href="">
                <img src={IconF1} alt="" />
              </a>
              <a
                href="https://twitter.com/NyanCatSolana"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={IconF2} alt="" />
              </a>
              <a
                href="https://t.me/NyanCatSolana"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={IconF3} alt="" />
              </a>
            </div>
          </div>
          <p className="copyright">
            Copyright © 2023 - All Rights Reserved By
            <span> $NYAN CAT</span>
          </p>
        </footer>
      </div>
    </div>
  );
}

export default App;
